<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">
      <div class="home">
        <EventRegionsComponent/>
      </div>
    </transition>
  </div>
</template>

<script>
// @ is an alias to /src
import EventRegionsComponent from '@/components/EventRegionsComponent.vue'

export default {
  name: 'ContestRegions',
  components: {
    EventRegionsComponent
  }
}
</script>

<style lang="scss">
@import "../styles/main.scss";
</style>

<style scoped lang="scss">
</style>
